import { toastNotify } from '@components/toast';

export const apiErrorHandler = (error, defaultMessage = 'Could not process request') => {
	error?.data?.message
		? toastNotify(error?.data?.message, 'error')
		: error?.message
		? error?.message === 'Network Error'
			? toastNotify('Check Your Internet Connection', 'error')
			: toastNotify(error?.message, 'error')
		: toastNotify(defaultMessage, 'error');
};
